export const BREAKPOINT = "768px";
export const BREAKPOINT_TOPNAV = "1020px";
export const XS = "374px";
export const LG = "992px";
export const XL = "1200px";
export const XXL = "1440px";

export const navItems = [{ label: "Own a Marina or Space?", path: "/" }];

export const navItems2 = [
  { label: "Home", path: "#home" },
  { label: "Boater Features", path: "/boater" },
  { label: "Marine Management Software", path: "https://getmolo.com/" }
];
