import React from "react";
import styled from "styled-components";

const Wrap = styled.div`
  width: 100%;
  text-align: center;
`;

const BcgImg = props => (
  <Wrap>
    {/*<picture>*/}
    {/* TODO: Add src set when implementing api */}
    <img {...props} style={{ height: "auto" }} alt="" />
    {/*</picture>*/}
  </Wrap>
);

export default BcgImg;
