import React from "react";
import styled from "styled-components";

import PlayStoreImg from "../assets/img/play-store-badge.svg";

const Elevation = styled.img`
  box-shadow: var(--elevation-1);
  border-radius: 10px;
  height: 60px;
  width: 190px;
`;

const PlayStoreBadge = () => (
  <a href="https://play.google.com/">
    <Elevation
      src={PlayStoreImg}
      height={60}
      width={190}
      alt="Play Store Badge"
    />
  </a>
);

export default PlayStoreBadge;
