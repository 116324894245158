import React from "react";
import PropTypes from "prop-types";

import { ReactComponent as Mail } from "../../assets/img/ic-mail.svg";
import { ReactComponent as MailBlue } from "../../assets/img/ic-mail-blue.svg";
import { ReactComponent as Call } from "../../assets/img/ic-call.svg";
import { ReactComponent as Hamburger } from "../../assets/img/ic-hamburger.svg";

const IconFactory = ({ icon }) => {
  switch (icon) {
    case "Call":
      return (
        <div>
          <Call />
        </div>
      );
    case "Mail":
      return (
        <div>
          <Mail />
        </div>
      );
    case "MailBlue":
      return (
        <div style={{ height: "18px" }}>
          <MailBlue />
        </div>
      );
    case "Hamburger":
      return (
        <div>
          <Hamburger />
        </div>
      );
    default:
      return null;
  }
};

IconFactory.propTypes = {
  icon: PropTypes.oneOf(["Call", "Mail", "Hamburger", "MailBlue"]).isRequired
};

export default IconFactory;
