import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import IconFactory from "./IconFactory";

const IconButtonWrap = styled.button`
  display: inline-flex;
  align-items: center;
  padding: 0 12px;
  background-color: transparent;
  height: 42px;

  border: ${props => {
    if (props.border) {
      if (props.inverted) {
        return "1px solid var(--brand-lightblue)";
      }
      return "1px solid var(--system-white)";
    }
    return "unset";
  }};
  border-radius: 22px;

  :hover {
    cursor: pointer;
  }

  span {
    margin-left: 8px;

    color: ${({ inverted }) =>
      inverted ? "var(--brand-lightblue)" : "var(--system-white)"};
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const IconButton = ({
  action,
  icon,
  children,
  border,
  inverted,
  style = {}
}) => (
  <IconButtonWrap
    onClick={action}
    border={border}
    style={style}
    inverted={inverted}
  >
    <IconFactory icon={icon} />
    {children && <span>{children}</span>}
  </IconButtonWrap>
);

IconButton.propTypes = {
  action: PropTypes.func,
  border: PropTypes.bool,
  inverted: PropTypes.bool,
  children: PropTypes.string
};

IconButton.defaultProps = {
  action: () => {},
  border: false,
  inverted: false
};

export default IconButton;
