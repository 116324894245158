import React from "react";
import styled from "styled-components";

import { BREAKPOINT } from "../constants";
import { mobileOperatingSystem, deviceType } from "../helpers";

import AppStoreBadge from "./AppStoreBadge";
import PlayStoreBadge from "./PlayStoreBadge";

const ImgWrap = styled.div`
  display: inline-flex;
  flex-flow: column;

  @media (min-width: ${BREAKPOINT}) {
    flex-flow: row;
    > a:first-child {
      margin-right: 20px;
    }
  }
`;

const StoreBadges = () => {
  const os = mobileOperatingSystem();
  const desktop = deviceType() === "desktop";

  return (
    <ImgWrap>
      {(desktop || os === "iOS" || os === "unknown") && <AppStoreBadge />}
      {(desktop || os === "Android" || os === "unknown") && <PlayStoreBadge />}
    </ImgWrap>
  );
};

export default StoreBadges;
