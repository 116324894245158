import React from "react";
import styled from "styled-components";

import { BREAKPOINT } from "../../constants";

import MobileImg from "../../assets/img/img-section-0-marina.png";
import MobileImgMd from "../../assets/img/img-section-0-2x-marina.png";
import MobileImgLg from "../../assets/img/img-section-0-3x-marina.png";

const Wrap = styled.div`
  @media (min-width: ${BREAKPOINT}) {
    display: none;
  }
`;

const BcgImgMarina = () => (
  <Wrap>
    <picture>
      <source srcSet={MobileImgMd} media="(min-width: 700px)" />
      <source srcSet={MobileImgLg} media="(min-width: 750px)" />

      <img style={{ width: "100%" }} src={MobileImg} alt="" />
    </picture>
  </Wrap>
);

export default BcgImgMarina;
