import styled from "styled-components";

export default styled.button`
  display: inline-flex;
  align-items: center;
  text-align: center;
  padding: 0 16px;
  background-color: var(
    ${({ inverted }) => (inverted ? "--system-white" : "--brand-blue")}
  );
  height: 42px;
  color: var(
    ${({ inverted }) => (inverted ? "--brand-lightblue" : "--system-white")}
  );
  border-radius: 22px;
  font-size: 14px;

  :hover {
    cursor: pointer;
    opacity: 0.9;
  }
`;
