import Landing1 from "./hoc/Landing1";
import Landing2 from "./hoc/Landing2";

export default [
  {
    name: "Client LP",
    exact: true,
    path: "/boater",
    Component: Landing1
  },
  {
    name: "Marina LP",
    exact: true,
    path: "/",
    Component: Landing2
  }
];
