import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

const AppContainer = styled.div`
  position: relative;
`;

const Layout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggle = () => setIsSidebarOpen(!isSidebarOpen);

  return (
    <AppContainer>
      <Sidebar isOpen={isSidebarOpen} toggle={toggle} />
      <Header toggleSidebar={toggle} />

      <main>{children}</main>

      <Footer />
    </AppContainer>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
