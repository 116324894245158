import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Transition } from "react-transition-group";

import { navItems } from "../../constants";

import { ReactComponent as Close } from "../../assets/img/ic-close.svg";

import { IconButton } from "../../components/common";

const backdropDefault = {
  transition: `opacity 100ms ease-in-out`,
  opacity: 0
};
const backdropTrans = {
  entering: { opacity: 1, zIndex: 101 },
  entered: { opacity: 1, zIndex: 101 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 }
};

const sidebarDefault = {
  transition: `all 300ms ease-in-out`,
  right: "-100%"
};
const sidebarTrans = {
  entering: { right: "0%" },
  entered: { right: "0%" },
  exiting: { right: "-100%" },
  exited: { right: "-100%" }
};

const Backdrop = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
`;

const SidebarWrap = styled.div`
  position: fixed;
  z-index: 101;
  top: 0;
  right: 0;
  height: 100%;
  width: calc(100% - 40px);
  background: white;

  padding: 40px;
`;

const CloseWrap = styled.div`
  float: right;
  margin: -10px -20px 0 0;
  padding: 10px;

  :hover {
    cursor: pointer;
  }
`;

const Nav = styled.nav`
  display: flex;
  flex-flow: column;
  margin: 40px 0 30px;
  a {
    display: flex;
    align-items: center;
    height: 50px;
    width: 100%;
    color: var(--brand-lightblue);
    border-bottom: 1px solid var(--brand-lighterblue);
  }
`;

const Sidebar = ({ isOpen, toggle }) => (
  <>
    <Transition in={isOpen} timeout={200}>
      {state => (
        <Backdrop
          onClick={toggle}
          isOpen={isOpen}
          style={{
            ...backdropDefault,
            ...backdropTrans[state]
          }}
        />
      )}
    </Transition>
    <Transition in={isOpen} timeout={300}>
      {state => (
        <SidebarWrap
          isOpen={isOpen}
          style={{
            ...sidebarDefault,
            ...sidebarTrans[state]
          }}
        >
          <CloseWrap onClick={toggle}>
            <Close />
          </CloseWrap>
          <Nav>
            {navItems.map(i => (
              <a key={i.path} href={i.path}>
                {i.label}
              </a>
            ))}
          </Nav>
          <IconButton
            icon={"MailBlue"}
            action={() => window.open("mailto:support@getmolo.com")}
            border
            inverted
            style={{
              padding: "0 30px",
              height: "50px",
              borderRadius: "25px"
            }}
          >
            Email Support
          </IconButton>
        </SidebarWrap>
      )}
    </Transition>
  </>
);

Sidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
};

export default Sidebar;
