import React from "react";
import styled from "styled-components";

import { BREAKPOINT } from "../../constants";
import { mobileOperatingSystem, deviceType } from "../../helpers";
import { Row, Col } from "../../components/common";
import AppStoreBadge from "../../components/AppStoreBadge";
import PlayStoreBadge from "../../components/PlayStoreBadge";
import SubscriptionForm from "../../components/SubscriptionForm";

const FooterWrap = styled.footer`
  background-color: var(--brand-blue);
  padding: 60px 40px 40px;

  color: var(--system-white);
  text-align: center;

  h2 {
    font-size: 32px;
    line-height: 40px;
    font-weight: normal;
    margin-bottom: 0;

    strong {
      font-weight: bolder;
    }
  }

  p {
    line-height: 16px;
    align-self: center;
    margin-bottom: 20px;
  }

  a {
    color: var(--system-white);
    text-decoration: none;
  }

  @media (min-width: ${BREAKPOINT}) {
    padding: 94px 45px 40px;

    h2 {
      font-size: 48px;
      line-height: 56px;
    }

    p {
      align-self: flex-start;
    }
  }
`;

const ImgWrap = styled.div`
  align-self: center;
  display: flex;
  flex-flow: column;

  margin: 40px 0 60px 0;
  @media (min-width: ${BREAKPOINT}) {
    margin-bottom: 94px;
    flex-flow: row;
    > a:first-child {
      margin-right: 20px;
    }
  }
`;

const FooterBottom = styled.div`
  p {
    font-size: 16px;
    line-height: 16px;
  }
  @media (max-width: ${BREAKPOINT}) {
  }
`;

const Footer = props => {
  const os = mobileOperatingSystem();
  const desktop = deviceType() === "desktop";

  return (
    <FooterWrap>
      {/*<Row>
        <Col>

            <h2>
              Get <strong>Pay With Molo</strong> for free!
            </h2>

        </Col>
      </Row>

        <Row>
          <Col>
            <ImgWrap>
              {(desktop || os === "iOS" || os === "unknown") && (
                <AppStoreBadge />
              )}

              {(desktop || os === "Android" || os === "unknown") && (
                <PlayStoreBadge />
              )}
            </ImgWrap>
          </Col>
        </Row>
      */}
      <Row>
        <SubscriptionForm inverted />
      </Row>

      <FooterBottom>
        <Row>
          <Col>
            <p>
              +18556058100 •{" "}
              <a
                href="mailto:support@getmolo.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                support@getmolo.com
              </a>
            </p>
          </Col>
          <Col>
            <p style={desktop ? { alignSelf: "flex-end" } : {}}>
              © {new Date().getFullYear()} Molo, Inc. All Rights Reserved.
            </p>
          </Col>
        </Row>
      </FooterBottom>
    </FooterWrap>
  );
};

export default Footer;
