import styled from "styled-components";

export default styled.label`
  text-align: left;
  color: var(
    ${({ inverted }) => (inverted ? "--system-white" : "--brand-lightblue")}
  );
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 2px;
  text-transform: uppercase;
`;
