import React, { useRef, useEffect } from "react";
import styled from "styled-components";

import { Input, Label, FormGroup, Button } from "../common";

const Wrap = styled.div`
  margin: 0 auto 40px;
`;
const Title = styled.h1`
  color: var(
    ${({ inverted }) => (inverted ? "--system-white" : "--brand-blue")}
  );
`;

const Form = styled.form`
  display: flex;
  flex-flow: column;
`;

const FormContent = styled.div`
  margin-bottom: 30px;
`;

const SubscriptionForm = ({ inverted }) => {
  // HOOKS
  const wrapRef = useRef(null);

  useEffect(() => {
    const script = document.createElement("script");

    script.type = "text/javascript";
    script.src =
      "https://js.createsend1.com/javascript/copypastesubscribeformlogic.js";
    script.async = true;

    document.getElementById("sf-wrap").appendChild(script);
  }, []);

  return (
    <Wrap id="sf-wrap" ref={wrapRef}>
      <div id={"subscriptionForm"}>
        <Title inverted={inverted}>I'm Interested</Title>
        <Form
          className="js-cm-form"
          id="subForm"
          action="https://www.createsend.com/t/subscribeerror?description="
          method="post"
          data-id="92D4C54F0FEC16E5ADC2B1904DE9ED1A021F8E50333C05670506D00AFB3D13F5EAB5CEA4980C4A63A3A476FCDFBBFCDEDB210BB492B1B589BC1B52794A5DDEC3"
        >
          <FormContent>
            <FormGroup>
              <Label inverted={inverted}>Name *</Label>
              <Input
                inverted={inverted}
                aria-label="Name"
                id="fieldName"
                maxLength="200"
                name="cm-name"
                required=""
              />
            </FormGroup>
            <FormGroup>
              <Label inverted={inverted}>Email *</Label>
              <Input
                inverted={inverted}
                autoComplete="Email"
                aria-label="Email"
                className="js-cm-email-input"
                id="fieldEmail"
                maxLength="200"
                name="cm-ykdkilj-ykdkilj"
                required=""
                type="email"
              />
            </FormGroup>
            <FormGroup>
              <Label inverted={inverted}>Marina Name *</Label>
              <Input
                inverted={inverted}
                aria-label="Marina Name"
                id="fieldykirbu"
                maxLength="200"
                name="cm-f-ykirbu"
                required=""
              />
            </FormGroup>
            <FormGroup>
              <Label inverted={inverted}>Mobile Number *</Label>
              <Input
                inverted={inverted}
                aria-label="Mobile Number"
                id="fieldykirnl"
                maxLength="200"
                name="cm-f-ykirnl"
                required=""
              />
            </FormGroup>
          </FormContent>
          <Button
            type="submit"
            border
            style={{ alignSelf: "flex-end" }}
            inverted={inverted}
          >
            CONTACT ME
          </Button>
        </Form>
      </div>
    </Wrap>
  );
};

export default SubscriptionForm;
