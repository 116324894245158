import styled from "styled-components";
import { BREAKPOINT } from "../../constants";

export default styled.div`
  @media (min-width: ${BREAKPOINT}) {
    display: flex;
    flex-flow: column;
    height: 100%;
    justify-content: center;
  }
`;
