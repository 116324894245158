import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Layout2 from "../Layout2";
import SEO from "./SEO";
import Section0 from "../../components/Section0";
import Section1 from "../../components/Section1";
import Section2 from "../../components/Section2";
import Section3 from "../../components/Section3";
import Section4 from "../../components/Section4";
import SubscriptionSection from "../../components/SubscriptionSection";

const Hide = styled.div`
  display: ${({ hide }) => (hide ? "none" : "unset")};
`;

const Landing2 = ({ loading, content }) => (
  <Hide hide={loading}>
    <Layout2
      footerData={
        content && {
          footerPerksList: content.fields.footerPerksList,
          footerSignPricesList: content.fields.footerSignPricesList,
          footerDisplayPrice: content.fields.footerDisplayPrice
        }
      }
    >
      <SEO />

      <Section0
        screenshotImg={
          content
            ? {
                src: content.fields.headingScreenshot.fields.file.url,
                height:
                  content.fields.headingScreenshot.fields.file.details.image
                    .height,
                width:
                  content.fields.headingScreenshot.fields.file.details.image
                    .width
              }
            : {}
        }
        text={content && content.fields.headingText}
        theme="marina"
      />
      <Section1
        theme="marina"
        screenshotImg={
          content && content.fields.screenshots[0]
            ? {
                src: content.fields.screenshots[0].fields.file.url,
                height:
                  content.fields.screenshots[0].fields.file.details.image
                    .height,
                width:
                  content.fields.screenshots[0].fields.file.details.image.width
              }
            : {}
        }
        title={content && content.fields.sectionTitles[0]}
        text={content && content.fields.sectionTexts[0]}
      />
      <Section2
        screenshotImg={
          content && content.fields.screenshots[1]
            ? {
                src: content.fields.screenshots[1].fields.file.url,
                height:
                  content.fields.screenshots[1].fields.file.details.image
                    .height,
                width:
                  content.fields.screenshots[1].fields.file.details.image.width
              }
            : {}
        }
        title={content && content.fields.sectionTitles[1]}
        text={content && content.fields.sectionTexts[1]}
        theme="marina"
      />
      <Section3
        screenshotImg={
          content && content.fields.screenshots[2]
            ? {
                src: content.fields.screenshots[2].fields.file.url,
                height:
                  content.fields.screenshots[2].fields.file.details.image
                    .height,
                width:
                  content.fields.screenshots[2].fields.file.details.image.width
              }
            : {}
        }
        title={content && content.fields.sectionTitles[2]}
        text={content && content.fields.sectionTexts[2]}
        theme="marina"
      />
      <Section4
        screenshotImg={
          content && content.fields.screenshots[3]
            ? {
                src: content.fields.screenshots[3].fields.file.url,
                height:
                  content.fields.screenshots[3].fields.file.details.image
                    .height,
                width:
                  content.fields.screenshots[3].fields.file.details.image.width
              }
            : {}
        }
        title={content && content.fields.sectionTitles[3]}
        text={content && content.fields.sectionTexts[3]}
      />
      <SubscriptionSection />
    </Layout2>
  </Hide>
);

Landing2.propTypes = {
  theme: PropTypes.string
};

export default Landing2;
