import React from "react";
import styled from "styled-components";

import { BREAKPOINT } from "../../constants";

const Wrap = styled.div`
  width: 100%;

  @media (min-width: ${BREAKPOINT}) {
    padding-right: 66px;
  }
`;

const BcgImg = props => (
  <Wrap>
    {/*<picture>*/}
    {/* TODO: Add src set when implementing api */}
    <img {...props} style={{ height: "auto" }} alt="" />
    {/*</picture>*/}
  </Wrap>
);

export default BcgImg;
