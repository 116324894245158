import styled from "styled-components";
import { BREAKPOINT } from "../../constants";

export default styled.div`
  height: 100%;
  ${({ padding }) => {
    if (padding) {
      let p = "";
      if (padding.mb) {
        p += "padding: " + padding.mb + ";";
      }
      if (padding.ds) {
        p +=
          "@media(min-width: " +
          BREAKPOINT +
          ") { padding: " +
          padding.ds +
          "; }";
      }
      return p;
    }
  }}
`;
