import React from "react";
import styled from "styled-components";

import { BREAKPOINT_TOPNAV, navItems } from "../../../constants";
import { scrollToSection } from "../../../helpers";

import { IconButton } from "../../../components/common";
import NavLogo from "./NavLogo";

const HeaderWrap = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: var(--system-white);
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;

  @media (min-width: ${BREAKPOINT_TOPNAV}) {
    display: flex;
    padding: 40px 45px 30px;
  }
`;
const DesktopNav = styled.nav`
  display: none;
  flex-flow: row;

  width: 63%;
  min-width: 540px;
  max-width: 706px;
  justify-content: flex-end;
  align-items: center;
  a {
    margin-right: 16px;
  }
  @media (min-width: ${BREAKPOINT_TOPNAV}) {
    display: flex;
  }
`;

const MobileNav = styled.div`
  display: flex;
  flex-flow: row;
  @media (min-width: ${BREAKPOINT_TOPNAV}) {
    display: none;
  }
`;

const Header = ({ toggleSidebar }) => (
  <HeaderWrap>
    <NavLogo />

    <DesktopNav>
      {navItems.map(i => (
        <a key={i.path} href={i.path} onClick={() => scrollToSection(i.id)}>
          {i.label}
        </a>
      ))}
      <IconButton
        icon={"Mail"}
        action={() => window.open("mailto:support@getmolo.com")}
        border
        style={{ padding: "0 30px" }}
      >
        Email Support
      </IconButton>
    </DesktopNav>
    <MobileNav>
      <IconButton
        icon={"Call"}
        action={() => window.open("tel:+18556058100")}
        border
      >
        Call Support
      </IconButton>

      <IconButton
        icon={"Hamburger"}
        action={toggleSidebar}
        style={{ padding: "0px", marginLeft: "20px" }}
      />
    </MobileNav>
  </HeaderWrap>
);

export default Header;
