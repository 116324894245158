export const mobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
};

export const deviceType = () =>
  window.innerWidth >= 768 ? "desktop" : "mobile";

export const scrollToSection = sectionId => {
  const el = document.getElementById(sectionId);
  el && el.scrollIntoView();
};
