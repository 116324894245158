import React from "react";

import { Row, Col, Section, SectionContent } from "../common";
import SubscriptionForm from "../SubscriptionForm";

const SubscriptionSection = props => (
  <Section top={{ mb: 60, ds: 90 }}>
    <Row>
      <Col>
        <SectionContent padding={{ mb: "0 0 0 20px", ds: "0" }}>
          <div style={{ display: "flex" }}>
            <SubscriptionForm />
          </div>
        </SectionContent>
      </Col>
    </Row>
  </Section>
);

export default SubscriptionSection;
