import styled from "styled-components";
import { BREAKPOINT } from "../../constants";

export default styled.section`
  background-color: var(--system-white);
  ${({ top }) => {
    if (top) {
      let t = "";
      if (top.mb) {
        t += "padding-top: " + top.mb + "px;";
      }
      if (top.ds) {
        t +=
          "@media(min-width: " +
          BREAKPOINT +
          ") { padding-top: " +
          top.ds +
          "px; }";
      }
      return t;
    }
  }}
  :nth-child(odd) {
    background-color: var(--system-gray);
  }
`;
