import React from "react";
import styled from "styled-components";

import Layout from "../Layout";
import Section0 from "../../components/Section0";
import Section1 from "../../components/Section1";
import Section2 from "../../components/Section2";
import Section3 from "../../components/Section3";

const Hide = styled.div`
  display: ${({ hide }) => (hide ? "none" : "unset")};
`;

const Landing1 = ({ loading, content }) => (
  <Hide hide={loading}>
    <Layout>
      <Section0
        screenshotImg={
          content
            ? {
                src: content.fields.headingScreenshot.fields.file.url,
                height:
                  content.fields.headingScreenshot.fields.file.details.image
                    .height,
                width:
                  content.fields.headingScreenshot.fields.file.details.image
                    .width
              }
            : {}
        }
        text={content && content.fields.headingText}
      />
      <Section1
        screenshotImg={
          content && content.fields.screenshots[0]
            ? {
                src: content.fields.screenshots[0].fields.file.url,
                height:
                  content.fields.screenshots[0].fields.file.details.image
                    .height,
                width:
                  content.fields.screenshots[0].fields.file.details.image.width
              }
            : {}
        }
        title={content && content.fields.sectionTitles[0]}
        text={content && content.fields.sectionTexts[0]}
      />
      <Section2
        screenshotImg={
          content && content.fields.screenshots[1]
            ? {
                src: content.fields.screenshots[1].fields.file.url,
                height:
                  content.fields.screenshots[1].fields.file.details.image
                    .height,
                width:
                  content.fields.screenshots[1].fields.file.details.image.width
              }
            : {}
        }
        title={content && content.fields.sectionTitles[1]}
        text={content && content.fields.sectionTexts[1]}
      />
      <Section3
        screenshotImg={
          content && content.fields.screenshots[2]
            ? {
                src: content.fields.screenshots[2].fields.file.url,
                height:
                  content.fields.screenshots[2].fields.file.details.image
                    .height,
                width:
                  content.fields.screenshots[2].fields.file.details.image.width
              }
            : {}
        }
        title={content && content.fields.sectionTitles[2]}
        text={content && content.fields.sectionTexts[2]}
      />
    </Layout>
  </Hide>
);

export default Landing1;
