import React from "react";

import {
  Row,
  Col,
  Section,
  SectionContent,
  SectionTitle,
  SectionText,
  ContentWrap
} from "../common";
import Screenshot from "./Screenshot";
import ScreenshotMarina from "./ScreenshotMarina";

const Section1 = props => (
  <Section
    top={{ mb: 20, ds: 145 }}
    style={{ marginBottom: "-7px" }}
    id={props.id}
  >
    <Row reverse>
      <Col flex={1}>
        <SectionContent padding={{ mb: "0 22px 45px", ds: "0 45px 0 15px" }}>
          <ContentWrap>
            <SectionTitle
              theme={props.theme}
              dangerouslySetInnerHTML={{ __html: props.title }}
            />

            <SectionText dangerouslySetInnerHTML={{ __html: props.text }} />
          </ContentWrap>
        </SectionContent>
      </Col>
      <Col flex={1}>
        {props.theme === "marina" ? (
          <ScreenshotMarina {...props.screenshotImg} />
        ) : (
          <Screenshot {...props.screenshotImg} />
        )}
      </Col>
    </Row>
  </Section>
);

export default Section1;
