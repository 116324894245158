import React from "react";
import styled from "styled-components";

import { BREAKPOINT, XXL } from "../../constants";

import DesktopImg from "../../assets/img/img-section-0-desktop-marina.png";

const Wrap = styled.div`
  display: none;
  @media (min-width: ${BREAKPOINT}) {
    display: block;
    width: 100%;
    min-width: 534px;
    margin-left: auto;
  }
  img {
    @media (min-width: ${BREAKPOINT}) and (max-width: ${XXL}) {
      object-fit: cover;
      width: 100%;
      height: 534px;
    }
  }
`;

const BcgImgDesktopMarina = () => (
  <Wrap>
    <picture>
      <img style={{ width: "100%" }} src={DesktopImg} alt="" />
    </picture>
  </Wrap>
);

export default BcgImgDesktopMarina;
