import React from "react";
import styled from "styled-components";
import AppStoreImg from "../assets/img/app-store-badge.svg";

const Elevation = styled.img`
  box-shadow: var(--elevation-1);
  border-radius: 10px;
  height: 60px;
  width: 190px;
`;

const AppStoreBadge = () => (
  <a href="https://www.apple.com/">
    <Elevation
      src={AppStoreImg}
      height={60}
      width={190}
      alt="App Store Badge"
    />
  </a>
);

export default AppStoreBadge;
