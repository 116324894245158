import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ContentfulClient, ContentfulProvider, Query } from "react-contentful";

import routes from "./routes.js";

const contentfulClient = new ContentfulClient({
  accessToken: "W6zG6WjHDBvUzicYn1VE6lb2R1PsYZlkOT8wmveodZU",
  space: "ldnnzs8mimex"
});

function App() {
  return (
    <ContentfulProvider client={contentfulClient}>
      <Query contentType="page">
        {({ data, error, fetched, loading }) => {
          return (
            <Router>
              {routes.map(({ name, exact, path, Component }, i) => (
                <Route
                  key={i}
                  exact={exact}
                  path={path}
                  render={() => {
                    let content = null;
                    if (data)
                      content = data.items.find(i => i.fields.page === name);
                    return (
                      <Component
                        loading={loading || !fetched}
                        error={error}
                        content={content}
                      />
                    );
                  }}
                />
              ))}
            </Router>
          );
        }}
      </Query>
    </ContentfulProvider>
  );
}

export default App;
