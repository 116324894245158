import React from "react";
import styled from "styled-components";

import { BREAKPOINT, XS } from "../../constants";
import { mobileOperatingSystem, deviceType } from "../../helpers";
import { Row, Col } from "../../components/common";
import AppStoreBadge from "../../components/AppStoreBadge";
import PlayStoreBadge from "../../components/PlayStoreBadge";

const FooterWrap = styled.footer`
  background-color: var(--brand-blue);
  padding: 60px 40px 40px;

  color: var(--system-white);
  text-align: center;

  h2 {
    font-size: 32px;
    line-height: 40px;
    font-weight: normal;
    margin-bottom: 0;

    strong {
      font-weight: bolder;
    }
  }

  p {
    font-size: 16px;
    line-height: 16px;
    align-self: center;
    margin-bottom: 20px;
  }

  a {
    color: var(--system-white);
    text-decoration: none;
  }

  @media (min-width: ${BREAKPOINT}) {
    padding: 94px 45px 40px;

    h2 {
      font-size: 48px;
      line-height: 56px;
    }

    p {
      align-self: flex-start;
    }
  }

  @media (max-width: ${BREAKPOINT}) {
    padding: 40px 20px;
  }
`;

const ImgWrap = styled.div`
  align-self: center;
  display: flex;
  flex-flow: column;

  margin: 40px 0 60px 0;
  @media (min-width: ${BREAKPOINT}) {
    margin-bottom: 94px;
    flex-flow: row;
    > a:first-child {
      margin-right: 20px;
    }
  }
  @media (max-width: ${BREAKPOINT}) {
    margin: 40px 0;
  }
`;

const Items = styled.div`
  text-align: left;
  margin-bottom: 10px;
  > div {
    margin-bottom: 20px;
    position: relative;
    padding-left: 25px;
  }
  span {
    position: relative;
    left: -15px;
    font-style: normal;
  }
  @media (max-width: ${BREAKPOINT}) {
    font-size: 24px;
    line-height: 30px;
  }
`;

const Square = styled.div`
  text-align: left;
  border-radius: 12px;
  border: 1px solid white;
  margin-bottom: 20px;
  padding: 19px 22px;
  @media (max-width: ${BREAKPOINT}) {
    font-size: 24px;
    line-height: 30px;
  }
`;

const SquareWhite = styled.div`
  background-color: white;
  color: var(--brand-lightblue);
  text-align: center;
  border-radius: 12px;
  padding: 42px 26px;
  max-width: 400px;
  align-self: center;
  display: flex;
  flex-flow: column;
  strong {
    font-size: 24px;
    line-height: 28px;
  }
  .text {
    color: var(--brand-darkblue);
    font-size: 24px;
    line-height: 28px;
    font-weight: normal;
    @media (max-width: ${XS}) {
      font-size: 20px;
      line-height: 24px;
    }
  }
  @media (max-width: ${BREAKPOINT}) {
    max-width: 100%;
  }
`;

const Price = styled.div`
  font-size: 120px;
  font-weight: bold;
  line-height: 141px;
  @media (max-width: ${BREAKPOINT}) {
    position: relative;
    padding-bottom: 18px;
  }
  @media (max-width: ${XS}) {
    font-size: 100px;
  }
  span {
    font-size: 24px;
    line-height: 28px;
    font-weight: normal;
    @media (max-width: ${BREAKPOINT}) {
      ::last-child {
        line-height: normal;
        position: absolute;
        top: 130px;
        left: 0;
        width: 100%;
        text-align: center;
      }
    }
  }
  ::first-child {
    font-size: 76px;
    font-weight: normal;
    line-height: 132px;
    @media (max-width: ${XS}) {
      font-size: 58px;
    }
  }
`;

const FooterBottom = styled.div`
  margin-top: 40px;
  @media (max-width: ${BREAKPOINT}) {
    margin-top: 20px;
    text-align: center;
  }
`;

const Footer = ({ data }) => {
  const os = mobileOperatingSystem();
  const desktop = deviceType() === "desktop";
  if (!data)
    data = {
      footerDisplayPrice: "",
      footerPerksList: [""],
      footerSignPricesList: [""]
    };

  return (
    <FooterWrap>
      <Row reverse>
        <Col
          style={
            desktop ? { alignItems: "center", justifyContent: "center" } : {}
          }
        >
          <SquareWhite>
            <strong>Starting at</strong>
            <Row>
              <Price
                dangerouslySetInnerHTML={{
                  __html: data.footerDisplayPrice
                }}
              />
            </Row>
            <div className="text">for up to 100 spaces.</div>
          </SquareWhite>

          {/*<ImgWrap>
                {(desktop || os === "iOS" || os === "unknown") && (
                  <AppStoreBadge />
                )}

                {(desktop || os === "Android" || os === "unknown") && (
                  <PlayStoreBadge />
                )}
              </ImgWrap>*/}
        </Col>
        <Col>
          <Items>
            {data.footerPerksList.map((p, i) => (
              <div
                key={i}
                dangerouslySetInnerHTML={{ __html: `<span>•</span>${p}` }}
              ></div>
            ))}
          </Items>
          {data.footerSignPricesList.map((s, i) => (
            <Square key={i} dangerouslySetInnerHTML={{ __html: s }} />
          ))}
        </Col>
      </Row>
      <FooterBottom>
        <Row reverse>
          <Col>
            <p style={desktop ? { alignSelf: "flex-end" } : {}}>
              +18554126656 •{" "}
              <a
                href="mailto:support@getmolo.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                support@getmolo.com
              </a>
            </p>
          </Col>
          <Col>
            <p style={desktop ? { alignSelf: "flex-start" } : {}}>
              © {new Date().getFullYear()} Molo, Inc. All Rights Reserved.
            </p>
          </Col>
        </Row>
      </FooterBottom>
    </FooterWrap>
  );
};

export default Footer;
