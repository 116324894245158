import styled from "styled-components";
import { BREAKPOINT } from "../../constants";

export default styled.h2`
  margin-bottom: 20px;
  @media (min-width: ${BREAKPOINT}) {
    margin-bottom: 40px;
  }
  font-size: 48px;
  line-height:56px;
  @media (max-width: ${BREAKPOINT}) {
    font-size: 28px;
    line-height: 33px;
  }
`;
