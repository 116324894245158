import React from "react";
import styled from "styled-components";

import { BREAKPOINT } from "../../constants";

import DesktopImg from "../../assets/img/img-section-0-desktop.png";

const Wrap = styled.div`
  display: none;
  @media (min-width: ${BREAKPOINT}) {
    display: block;
    width: 65.6%;
    max-width: 935px;
    min-height: 595px;
    margin-left: auto;
  }
`;

const BcgImgDesktop = () => (
  <Wrap>
    <picture>
      <img style={{ width: "100%" }} src={DesktopImg} alt="" />
    </picture>
  </Wrap>
);

export default BcgImgDesktop;
