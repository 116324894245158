import styled from "styled-components";

export default styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  @media (min-width: 768px) {
    flex-flow: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
  }
`;
