import React from "react";
import styled from "styled-components";

import { scrollToSection } from "../../helpers";
import { BREAKPOINT, XXL } from "../../constants";

import { Row, Col, SectionContent, SectionBcgImg, Button } from "../common";
import BcgImg from "./BcgImg";
import BcgImgDesktop from "./BcgImgDesktop";
import BcgImgMarina from "./BcgImgMarina";
import BcgImgDesktopMarina from "./BcgImgDesktopMarina";
import Screenshot from "./Screenshot";
import StoreBadges from "../StoreBadges";

const ScreenshotWrap = styled.div`
  @media (min-width: ${BREAKPOINT}) {
    position: relative;
  }
`;

const ScreenshotTextWrap = styled.div`
  display: none;
  @media (min-width: 1035px) {
    display: block;
    position: absolute;
    bottom: 8%;
    right: 32px;

    font-weight: 500;
    p {
      font-size: 10px;
      line-height: 14px;
    }

    a {
      font-size: 14px;
      line-height: 18px;
      color: var(--brand-lightblue);
    }
  }
  @media (min-width: 1330px) {
    p {
      font-size: 16px;
      line-height: 18px;
    }

    a {
      font-size: 24px;
      line-height: 28px;
    }
  }
  /*@media (min-width: 1600px) {
    bottom: -8%;
  }*/
`;

const ContentWrap = styled.div`
  margin-top: 2vw;
  @media (min-width: ${BREAKPOINT}) {
    margin-top: unset;

    display: flex;
    flex-flow: column;
    height: 100%;
    justify-content: center;
  }
`;

const MainHeading = styled.h1`
  font-family: var(--main-font);
  font-size: 48px;
  line-height: 56px;
  font-weight: 500;
  margin-bottom: 16px;
  span {
    color: var(--brand-lightblue);
  }

  @media (min-width: ${BREAKPOINT}) {
    font-size: 72px;
    line-height: 84px;
    margin-bottom: 18px;
  }
`;

const StoreWrap = styled.div`
  margin: 40px 0;
  text-align: center;

  @media (min-width: ${BREAKPOINT}) {
    text-align: unset;
  }
  @media (max-width: ${BREAKPOINT}) {
    margin: 26px 0;
  }
`;

const Title = styled.div`
  font-family: var(--main-font);
  font-weight: 500;
  padding-top: 10px;
  color: white;
  font-size: 72px;
  line-height: 80px;
  min-width: 605px;
  margin-bottom: 110px;
  @media (max-width: ${XXL}) {
    font-size: 48px;
    line-height: 58px;
    padding-top: 30px;
    margin-bottom: 160px;
    min-width: auto;
  }
  @media (min-width: 1600px) {
    padding: 10% 0;
    margin-bottom: 22%;
  }
  @media (max-width: ${BREAKPOINT}) {
    color: var(--brand-darkblue);
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px;
  }
`;

const ButtonCTAWrap = styled.div`
  margin-bottom: 20px;
  @media (min-width: ${BREAKPOINT}) {
    margin-top: -140px;
  }
`;
const ButtonCTA = styled(Button)`
  height: 48px;
  line-height: 48px;
  width: 160px;
  font-size: 18px;
  padding-left: 24px;
  border-radius: 24px;
`;

const Section0 = props => (
  <section>
    <SectionBcgImg>
      {props.theme === "marina" ? <BcgImgMarina /> : <BcgImg />}
      {props.theme === "marina" ? <BcgImgDesktopMarina /> : <BcgImgDesktop />}
    </SectionBcgImg>

    <SectionContent padding={{ mb: "104px 22px 0", ds: "133px 45px 0" }}>
      <Row reverse>
        <Col flex={3}>
          <ScreenshotWrap>
            <Screenshot {...props.screenshotImg} />

            <ScreenshotTextWrap>
              <p>Call support:</p>
              <a href="tel:+18556058100">+18556058100</a>
            </ScreenshotTextWrap>
          </ScreenshotWrap>
        </Col>
        <Col flex={2}>
          {props.theme === "marina" ? (
            <>
              <Title>Collect Payments Seamlessly from Boaters!</Title>

              <ButtonCTAWrap>
                <ButtonCTA onClick={() => scrollToSection("subscriptionForm")}>
                  I'm Interested
                </ButtonCTA>
              </ButtonCTAWrap>
            </>
          ) : null}
          <ContentWrap>
            <div>
              <div>
                {props.theme !== "marina" ? (
                  <MainHeading>
                    Pay With <span>Molo</span>
                  </MainHeading>
                ) : null}
                <div dangerouslySetInnerHTML={{ __html: props.text }} />
              </div>
              <StoreWrap>
                {/*<StoreBadges />*/}
                {props.theme !== "marina" ? (
                  <ButtonCTA
                    onClick={() => scrollToSection("subscriptionForm")}
                  >
                    I'm Interested
                  </ButtonCTA>
                ) : null}
              </StoreWrap>
            </div>
          </ContentWrap>
        </Col>
      </Row>
    </SectionContent>
  </section>
);

export default Section0;
