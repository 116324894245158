import React from "react";
import styled from "styled-components";

import { BREAKPOINT } from "../../../constants";

import { ReactComponent as NavLogoImg } from "../../../assets/img/pay-with-molo-nav-logo.svg";
import { ReactComponent as NavLogoImgDesktop } from "../../../assets/img/pay-with-molo-nav-logo-desktop.svg";

const MobileWrap = styled.div`
  display: inline-block;
  @media (min-width: ${BREAKPOINT}) {
    display: none;
  }
`;

const DesktopWrap = styled.div`
  display: none;
  @media (min-width: ${BREAKPOINT}) {
    display: inline-block;
  }
`;

const Navlogo = props => (
  <div>
    <MobileWrap>
      <NavLogoImg />
    </MobileWrap>
    <DesktopWrap>
      <NavLogoImgDesktop />
    </DesktopWrap>
  </div>
);

export default Navlogo;
