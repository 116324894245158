import React from "react";
import styled from "styled-components";

import { BREAKPOINT } from "../../../constants";

import { ReactComponent as NavLogoImg } from "../../../assets/img/pay-with-molo-nav-logo.svg";
import { ReactComponent as NavLogoImgWhite } from "../../../assets/img/pay-with-molo-nav-logo-white.svg";
import { ReactComponent as NavLogoImgDesktop } from "../../../assets/img/pay-with-molo-nav-logo-desktop.svg";
import { ReactComponent as NavLogoImgDesktopWhite } from "../../../assets/img/pay-with-molo-nav-logo-desktop-white.svg";

const MobileWrap = styled.div`
  display: inline-block;
  svg {
    max-width: 128px;
    max-height: 64px;
  }
  @media (min-width: ${BREAKPOINT}) {
    display: none;
  }
`;

const DesktopWrap = styled.div`
  display: none;
  @media (min-width: ${BREAKPOINT}) {
    display: inline-block;
  }
`;

const Navlogo = props => (
  <div>
    <MobileWrap>
      {props.theme === 'white' ? <NavLogoImgWhite /> : <NavLogoImg />}
    </MobileWrap>
    <DesktopWrap>
      {props.theme === 'white' ? <NavLogoImgDesktopWhite /> : <NavLogoImgDesktop />}
    </DesktopWrap>
  </div>
);

export default Navlogo;
