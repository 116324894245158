import React from "react";

import {
  Row,
  Col,
  Section,
  SectionContent,
  SectionTitle,
  SectionText,
  ContentWrap
} from "../common";
import Screenshot from "./Screenshot";
import ScreenshotMarina from "./ScreenshotMarina";

const Section2 = props => (
  <Section top={{ mb: 60, ds: 90 }}>
    <Row>
      <Col flex={2}>
        <SectionContent padding={{ mb: "0 22px 45px", ds: "0 30px 0 45px" }}>
          <ContentWrap>
            <SectionTitle
              theme={props.theme}
              style={props.theme === "marina" ? { maxWidth: "350px" } : {}}
              dangerouslySetInnerHTML={{ __html: props.title }}
            />

            <SectionText dangerouslySetInnerHTML={{ __html: props.text }} />
          </ContentWrap>
        </SectionContent>
      </Col>
      <Col flex={3}>
        <SectionContent padding={{ mb: "0 0 0 20px", ds: "0" }}>
          {props.theme === "marina" ? (
            <ScreenshotMarina {...props.screenshotImg} />
          ) : (
            <Screenshot {...props.screenshotImg} />
          )}
        </SectionContent>
      </Col>
    </Row>
  </Section>
);

export default Section2;
