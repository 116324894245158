import React from "react";
import { Helmet } from "react-helmet";

const SEO = props => (
  <Helmet>
    <meta
      name="description"
      content="Pay With Molo allows you to add signs to your dockage, mooring, and launch ramps to allow boaters to pay fees in seconds from their smartphone."
    />
    <meta name="og:title" content="Marina | Pay With Molo" />
    <meta
      name="og:description"
      content="Pay With Molo allows you to add signs to your dockage, mooring, and launch ramps to allow boaters to pay fees in seconds from their smartphone."
    />
    <meta name="og:image" content="%PUBLIC_URL%/logo192.png" />
    <meta name="og:url" content="%PUBLIC_URL%/marina" />
    <meta name="og:type" content="website" />

    <meta name="twitter:card" content="summary" />
    <meta name="twitter:creator" content="Litebox @ Hari Solaas" />
    <meta name="twitter:title" content="Pay With Molo" />
    <meta
      name="twitter:description"
      content="Pay With Molo allows you to add signs to your dockage, mooring, and launch ramps to allow boaters to pay fees in seconds from their smartphone."
    />
    <title>Marina | Pay With Molo</title>
  </Helmet>
);

export default SEO;
