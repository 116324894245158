import React from "react";
import styled from "styled-components";

import { BREAKPOINT, XL, XXL } from "../../constants";

const Wrap = styled.div`
  min-width: 240px;
  max-width: 480px;
  width: 65.6%;
  margin: auto;

  @media (min-width: ${BREAKPOINT}) {
    margin: unset;
    margin-right: auto;
    margin-left: 63px;
  }
  @media (min-width: ${XL}) and (max-width: ${XXL}) {
    margin-right: auto;
  }
  @media (max-width: ${XL}) {
    margin-right: auto;
  }
`;

const Screenshot = props => (
  <Wrap>
    <picture>
      <img {...props} style={{ height: "auto" }} alt="" />
    </picture>
  </Wrap>
);

export default Screenshot;
