import styled from "styled-components";

export default styled.input`
  color: var(
    ${({ inverted }) => (inverted ? "--system-white" : "--brand-lightblue")}
  );
  font-size: 16px;
  line-height: 21px;
  padding-top: 5px;
  min-height: 21px;

  border-bottom: 1px solid
    ${({ inverted }) => (inverted ? "#fff9" : "var(--brand-lighterblue)")};
  :active,
  :focus {
    border-bottom: 1px solid
      ${({ inverted }) => (inverted ? "#ffff" : "var(--brand-lightblue)")};
  }
`;
